import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addToCart,
  getCart,
  removeCartItem,
  countToCart,
  commentToCart,
  gabariteToCart,
  cartFetchFail,
  cartCatchFail,
  cartLoading,
  cartSuccess,
} from '../actions';
import { ADD, REMOVE, GET, UPDATE, ORDER } from '../const';
import getLanguage from '../../../utils/getLanguage';

const API = process.env.REACT_APP_API_URL;
const API_URL = API + 'Cart';
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

async function request(url, options) {
  const response = await fetch(url, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return { status: response.status, msg: false };
  }
}

async function requestWithRes(url, options) {
  const response = await fetch(url, options);
  if (response.status === 200) {
    return { msg: true };
  } else {
    return { status: response.status, msg: false };
  }
}

export function* addTo(action) {
  const data = action.payload;
  const requestURL = API_URL;
  const options = {
    method: 'POST',
    body: JSON.stringify({
      goodId: data.item.id,
      toOrder: data.item.toOrder,
      count: data.item.count,
      comment: '',
      gabarite: false,
    }),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(cartFetchFail(prod.status));
    } else {
      const payload = { res: prod, count: data.item.count };
      yield put(addToCart(payload));
    }
  } catch (err) {
    yield put(cartCatchFail(err));
  }
}
export function* update(action) {
  yield put(cartLoading(true));
  const data = action.payload;

  const requestURL = `${API_URL}/update`;
  const options = {
    method: 'POST',
    body: JSON.stringify({
      goodId: data.data.goodId,
      toOrder: data.data.toOrder,
      count: data.action === 'count' ? data.count : data.data.count,
      comment: data.action === 'comment' ? data.comment : data.data.comment,
      gabarite: data.action === 'gabarite' ? data.check : data.data.gabarite,
      id: data.data.id,
    }),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(requestWithRes, requestURL, options);

    if (prod.msg === false) {
      yield put(cartFetchFail(prod.status));
    }
    if (prod.msg === true) {
      if (data.action === 'count') {
        yield put(countToCart({ id: data.data.id, count: data.count }));
      }
      if (data.action === 'comment') {
        yield put(commentToCart({ id: data.data.id, comment: data.comment }));
      }
      if (data.action === 'gabarite') {
        yield put(gabariteToCart({ id: data.data.id, gabarite: data.check }));
      }
    }
    yield put(cartLoading(false));
  } catch (err) {
    yield put(cartLoading(false));
    yield put(cartCatchFail(err));
  }
}
export function* remove(action) {
  const data = action.payload;

  const requestURL = `${API_URL}/delete`;
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(requestWithRes, requestURL, options);

    if (prod.msg === false) {
      yield put(cartFetchFail(prod.status));
    }
    if (prod.msg === true) {
      yield put(removeCartItem(data));
    }
  } catch (err) {
    yield put(cartCatchFail(err));
  }
}
export function* get(action) {
  yield put(cartLoading(true));
  const requestURL = API_URL;
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(cartLoading(false));
      yield put(cartFetchFail(prod.status));
    } else {
      yield put(cartLoading(false));
      yield put(getCart(prod));
    }
  } catch (err) {
    yield put(cartLoading(false));
    yield put(cartCatchFail(err));
  }
}
export function* order(action) {
  yield put(cartLoading(true));
  const data = action.payload;

  const requestURL = 'https://test.webapi.s-car.com.ua/api/Order';
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(requestWithRes, requestURL, options);
    if (prod.msg === false) {
      yield put(cartLoading(false));
      yield put(cartFetchFail(prod.status));
    } else {
      yield yield put(cartLoading(false));
      yield put(cartSuccess(true));
      yield call(delay, 3000);

      window.location.reload();
    }
  } catch (err) {
    yield put(cartLoading(false));
    yield put(cartCatchFail(err));
  }
}

export default function* aboutSaga() {
  yield takeLatest(ADD, addTo);
  yield takeLatest(REMOVE, remove);
  yield takeLatest(GET, get);
  yield takeLatest(UPDATE, update);
  yield takeLatest(ORDER, order);
}

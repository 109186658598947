export const API_URL = 'https://dev.letters.s-car.com.ua'; //"https://scar.azurewebsites.net"; //"https://dev.letters.s-car.com.ua";//"http://letters.s-car.com.ua";
export const API_STATIC_PAGE = API_URL + '/api/v1/Contents/view/';
export const API_STATIC_PAGE_ADMIN = API_URL + '/api/v1/Contents/';
export const API_LANGUAGE = API_URL + '/api/v1/Common/languages';
export const API_CONTACTS = API_URL + '/api/v1/Company/contacts';
export const API_OFFICE = API_URL + '/api/v1/Company/view/offices';
export const API_OFFICE_PUT = API_URL + '/api/v1/Company/offices';
export const API_REGISTER =
  'https://test.webapi.s-car.com.ua/api/user/registration';
export const API_LOGIN = 'https://test.webapi.s-car.com.ua/api/user/login';
export const API_GLOGIN =
  'https://test.webapi.s-car.com.ua/api/user/authenticate';
// 'https://dev2.s-car.com.ua/api/login';
// 'https://dev.webapi.s-car.com.ua/api/login';

export const API_REGION = API_URL + '/api/v1/Company/regions';
export const API_NEWS = API_URL + '/api/v1/News/view';
export const API_NEWS_ADMIN = API_URL + '/api/v1/News/';
export const DEBUG = true;
export const GOOGLE_MAP_API = 'AIzaSyDzOjrwcI1rI0qh3SbWNVWQ0Yf-Lxu9ezE';

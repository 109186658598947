import { useState, useEffect, useMemo } from 'react';
import { Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalForm from './ModalComment';
import { Link } from 'react-router-dom';
import getLanguage from '../../../../utils/getLanguage';

type Props = {
  invoices: [any];
  toInvoiceProductsFilterInvoiceNumber: Function;
  setSelectionInvoiceByInvoiceNumber: Function;
  selectionInvoiceByInvoiceNumber: string;
  selectionInvoiceByOrderNumber: string;
};

const Invoices = (props: Props) => {
  const {
    invoices,
    toInvoiceProductsFilterInvoiceNumber,
    setSelectionInvoiceByInvoiceNumber,
    selectionInvoiceByInvoiceNumber,
    selectionInvoiceByOrderNumber,
  } = props;
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const [paginationPages, setPaginationPages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>('');
  const [searchOrderNumber, setSearchOrderNumber] = useState<string>('');
  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState<string>('');
  const [time, setTime] = useState<number>(1);
  const [searchTime, setSearchTime] = useState<number>(0);

  const setTimeHandler = (monthValue) => {
    if (monthValue === 100) {
      setTime(monthValue);
      setSearchTime(0);
      return;
    }
    setTime(monthValue);
    const currDate = new Date();
    currDate.setMonth(currDate.getMonth() - monthValue);
    const selectedDateTimeStamp = currDate.getTime();
    setSearchTime(selectedDateTimeStamp);
  };

  const dropFilters = () => {
    setSearchOrderNumber('');
    setSearchInvoiceNumber('');
    setTimeHandler(100);
  };

  useEffect(() => {
    if (selectionInvoiceByInvoiceNumber) {
      dropFilters();
      setSearchInvoiceNumber(selectionInvoiceByInvoiceNumber);
    }
  }, [selectionInvoiceByInvoiceNumber]);

  useEffect(() => {
    if (selectionInvoiceByOrderNumber) {
      dropFilters();
      setSearchOrderNumber(selectionInvoiceByOrderNumber);
    }
  }, [selectionInvoiceByOrderNumber]);

  const filteredInvoices = useMemo(() => {
    const filteredData = invoices
      .filter((invoice) => {
        return (
          invoice.orderNumber.includes(searchOrderNumber) &&
          invoice.number.includes(searchInvoiceNumber)
        );
      })
      .filter((invoice) => {
        return new Date(invoice.date).getTime() >= searchTime;
      });
    setPage(1);

    return filteredData;
  }, [searchOrderNumber, searchInvoiceNumber, searchTime]);

  const paginationInvoices = useMemo(() => {
    let pages: any[] = [];
    for (
      let number = 1;
      number <= Math.ceil(filteredInvoices.length / itemsPerPage);
      number++
    ) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => setPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPaginationPages([...pages]);

    if (pages.length < page) {
      setPage(pages.length);
    }

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const currentData = filteredInvoices.slice(start, end);

    return currentData;
  }, [filteredInvoices, page, itemsPerPage]);

  useEffect(() => {
    setTimeHandler(1);
  }, []);

  const download = async (type: string, id: string) => {
    const requestURL = `https://test.webapi.s-car.com.ua/api/Order/invoices/${type}?invoiceNumber=${id}`;
    const options = {
      headers: {
        accept: 'application/json',
        'Accept-Language': getLanguage().toLowerCase(),
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    await fetch(requestURL, options)
      .then((res) => res.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `${id}.${type === 'excel' ? 'xls' : type}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <>
      <ModalForm show={show} setShow={setShow} modalData={modalData} />
      <Row>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            placeholder="Введите номер накладной"
            onChange={(e) => setSearchInvoiceNumber(e.target.value)}
            value={searchInvoiceNumber}
            // @ts-ignore:next-line
            disabled={searchOrderNumber || selectionInvoiceByInvoiceNumber}
            className="rounded-0"
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            placeholder="Введите номер заказа"
            onChange={(e) => setSearchOrderNumber(e.target.value)}
            value={searchOrderNumber}
            // @ts-ignore:next-line
            disabled={searchInvoiceNumber}
            className="rounded-0"
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setTimeHandler(+e.target.value)}
            value={time}
          >
            <option value={1}>1 месяц</option>
            <option value={3}>3 месяца</option>
            <option value={6}>6 месяцев</option>
            <option value={12}>Год</option>
            <option value={100}>Всё время</option>
          </Form.Control>
        </Form.Group>
        {selectionInvoiceByInvoiceNumber && (
          <div className="mb-3 col-6">
            Информация по заказу: {selectionInvoiceByInvoiceNumber}
            <span
              className="selection-by text-d-underline"
              onClick={() => {
                setSearchInvoiceNumber('');
                setSelectionInvoiceByInvoiceNumber('');
              }}
            >
              Показать все заказы
            </span>
          </div>
        )}
      </Row>
      <Table striped bordered hover responsive className="document-table">
        <thead>
          <tr>
            <th>Номер РН</th>
            <th>Дата РН</th>
            <th>Номер заказа</th>
            <th>Сумма</th>
            <th>Информация по выдаче/отгрузке</th>
            <th className="word-wrap">Комментарий</th>
            <th>Сформировать документ РН</th>
          </tr>
        </thead>
        <tbody>
          {paginationInvoices.map((invoice, ind) => {
            return (
              <tr key={ind}>
                <td
                  onClick={() =>
                    toInvoiceProductsFilterInvoiceNumber(invoice.number)
                  }
                  className="order-number-link text-d-underline"
                >
                  {invoice.number}
                </td>
                <td>{new Date(invoice.date).toLocaleDateString()}</td>
                <td
                  // onClick={() => orderNumLinkHandler(order.number)}
                  className="order-number-link text-d-underline"
                >
                  <Link
                    to={`/cabinet/orders?orderNumber=${invoice.orderNumber}`}
                  >
                    {invoice.orderNumber}
                  </Link>
                </td>
                <td>
                  {invoice.sum} {invoice.currency}
                </td>
                <td>
                  {invoice.deliveryType}{' '}
                  {new Date(invoice.deliveryDate).toLocaleDateString()}
                </td>
                <td
                  onClick={() => {
                    if (invoice.comment) {
                      setModalData(invoice.comment);
                      setShow(true);
                    }
                  }}
                >
                  {invoice.comment && (
                    <div className="comment">{invoice.comment}</div>
                  )}
                </td>
                <td>
                  <button
                    className="download_btn"
                    onClick={() => download('excel', invoice.number)}
                  >
                    Excel
                  </button>
                  <button
                    className="download_btn"
                    onClick={() => download('pdf', invoice.number)}
                  >
                    PDF
                  </button>
                </td>
              </tr>
            );
            // }
          })}
        </tbody>
      </Table>
      <div className="d-flex overflow-auto flex-wrap">
        <Form.Group
          style={{ marginRight: '0.75rem' }}
          className="flex-shrink-0 mt-3"
        >
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setItemsPerPage(+e.target.value)}
            value={itemsPerPage}
          >
            <option value={10}>10 строк</option>
            <option value={15}>15 строк</option>
            <option value={20}>20 строк</option>
            <option value={30}>30 строк</option>
            <option value={50}>50 строк</option>
          </Form.Control>
        </Form.Group>

        {paginationPages.length > 1 && (
          <Pagination className="mt-3">{paginationPages}</Pagination>
        )}
      </div>
    </>
  );
};

export default Invoices;

import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { profileService } from '../personal_profile/profile/services';
import Toast from 'react-bootstrap/Toast';
import getLanguage from '../../utils/getLanguage';
import GetSettlements from './np_api/getSettlements';
import GetWarehouses from './np_api/getWarehouses';
import { sagaOrder, toStep } from './actions';

interface DefaultRootState {
  cart: {
    products: [any];
    selectedProducts: [any];
    sum: number;
    selectedSum: { sum: number };
    error: any;
    loading: boolean;
    success: boolean;
  };
}

interface UserProfile {
  deliveryName: string;
  deliveryPhone: string;
}

const endpoints = {
  plans: 'plans',
  points: 'points',
  deliveryTypes: 'types',
};

const getWait = (products, selected, val) => {
  let waitMax = 0;
  let waitMin = 100000;

  // eslint-disable-next-line
  products.map((prod: any) => {
    if (selected.includes(prod.id)) {
      if (prod.wait > waitMax) {
        waitMax = prod.wait;
      }
      if (prod.wait < waitMin) {
        waitMin = prod.wait;
      }
    }
  });

  return val === 1 ? waitMax : waitMin;
};

const getDeliveryDate = (days: number, num: number) => {
  const currentDate = new Date();
  const allDays = days + num;

  for (let i = 1; i <= allDays; i++) {
    if (currentDate.getDay() === 6) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (currentDate.getDay() === 0) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return currentDate.toLocaleDateString();
};

async function request(endpoint) {
  const API_URL = 'https://test.webapi.s-car.com.ua/api/Delivery/' + endpoint;
  const TOKEN = localStorage.getItem('token');
  const req = fetch(API_URL, {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return req;
}

export default function CartOrder() {
  const cart = useSelector((state: DefaultRootState) => state.cart);
  const dispatch = useDispatch();

  const [profileData, setProfileData] = useState<UserProfile>({
    deliveryName: '',
    deliveryPhone: '',
  });
  const [deliveryPlanData, setDeliveryPlanData] = useState([]);
  const [deliveryTypeData, setDeliveryTypeData] = useState([]);
  const [deliveryPointsData, setDeliveryPointsData] = useState([]);

  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [delivery, setDelivery] = useState<any>('0');
  const [deliveryType, setDeliveryType] = useState('0');
  const [comment, setComment] = useState('');
  const [checkWaitAll, setCheckWaitAll] = useState(false);
  const [checkGabarite, setCheckGabarite] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);

  const [addressDeliveryCity, setAddressDeliveryCity] = useState('');
  const [deliveryPoints, setDeliveryPoints] = useState('0');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryPlan, setDeliveryPlan] = useState('0');
  const [deliveryComment, setDeliveryComment] = useState('');
  const [npCityRef, setNpCityRef] = useState<any>([]);
  const [npWarehous, setNpWarehous] = useState<any>([]);

  const [deliveryTransport, setDeliveryTransport] = useState('0');

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const deliveryHandler = (val) => {
    setDelivery(val);
    let delType: any = deliveryTypeData.filter(
      (item: any) => item.code === val
    );
    setDeliveryType(delType[0]?.type ? delType[0]?.type : '0');
  };

  const addressHandler = (val) => {
    setDeliveryPoints(val);
    let address: any = deliveryPointsData.filter(
      (item: any) => item.code === val
    );
    setAddressDeliveryCity(
      address[0]?.description ? address[0]?.description : ''
    );
  };

  const phoneHandler = (val) => {
    // if (val.length > 11) {
    setUserPhone(val);
    // }
  };

  const handleFetchData = async () => {
    const { data } = await profileService.getInfoAboutUser();
    console.log('data', data);

    setProfileData(data);
    if (data.deliveryName && typeof data.deliveryName === 'string') {
      setUserName(data.deliveryName);
    }
    if (data.deliveryPhone && typeof data.deliveryPhone === 'string') {
      // if (data.deliveryPhone.trim().length < 11) {
      if (data.deliveryPhone.trim().length > 9) {
        setUserPhone(data.deliveryPhone);
      }
    }

    const types = await request(endpoints.deliveryTypes);
    setDeliveryTypeData(types.length ? types : []);

    if (typeof data.mainDeliveryTypeName === 'string') {
      if (data.mainDeliveryTypeName.toLowerCase() === 'доставка по городу') {
        const findVal = types.find((item) => item.type === 'DeliveryInCity');
        if (findVal?.code) {
          setDeliveryType('DeliveryInCity');
          setDelivery(findVal.code);
        }
      } else if (data.mainDeliveryTypeName.toLowerCase() === 'самовывоз') {
        const findVal = types.find((item) => item.type === 'Pickup');
        if (findVal?.code) {
          setDeliveryType('Pickup');
          setDelivery(findVal?.code);
        }
      } else if (data.mainDeliveryTypeName.toLowerCase() === 'новая почта') {
        const findVal = types.find((item) => item.type === 'DeliveryByCarrier');
        if (findVal?.code) {
          setDeliveryType('DeliveryByCarrier');
          setDelivery(findVal?.code);
        }
      } else if (
        data.mainDeliveryTypeName.toLowerCase().includes('курьерская доставка')
      ) {
        const findVal = types.find(
          (item) =>
            item.type === 'CourierDelivery' &&
            item.name
              .toLowerCase()
              .includes(data.regionName ? data.regionName.toLowerCase() : null)
        );
        if (findVal?.code) {
        }
        setDeliveryType('CourierDelivery');
        setDelivery(findVal.code);
      }
    }
  };
  // MIN all = false min date +1 day
  // MAX all = false min date +31 day

  // MIN all = true max date +1 day
  // MAX all = true max date +31 day

  const getMinDate = (num) => {
    return getDeliveryDate(
      getWait(cart.products, cart.selectedProducts, 0),
      num
    )
      .split('.')
      .reverse()
      .join('-');
  };

  const getMaxDate = (num) => {
    return getDeliveryDate(
      getWait(cart.products, cart.selectedProducts, 1),
      num
    )
      .split('.')
      .reverse()
      .join('-');
  };

  const checkWaitAllHandler = () => {
    setDeliveryDate('');
    setCheckWaitAll((prev) => !prev);
  };

  useEffect(() => {
    handleFetchData();

    return () => {
      dispatch(toStep(1));
    };
  }, []);

  useEffect(() => {
    async function getDetailedData(endpoint) {
      const data = await request(endpoint);
      console.log('data', data);
      // if (endpoint === endpoints.plans) {
      //   setDeliveryPlanData(data);
      // }
      if (endpoint === endpoints.points) {
        setDeliveryPointsData(data);
        setDeliveryPoints(data.length ? data[0]?.code : '0');
        setAddressDeliveryCity(data.length ? data[0]?.description : '');
      }
    }

    if (deliveryType === 'DeliveryInCity' && !deliveryPlanData.length) {
      getDetailedData(endpoints.points);
      // getDetailedData(endpoints.plans);
    }

    if (deliveryType === 'CourierDelivery' && !deliveryPointsData.length) {
      getDetailedData(endpoints.points);
    }
  }, [deliveryType]);

  const sendOrder = () => {
    setShowError(false);
    setErrorMessage([]);
    if (validation()) {
      const data = {
        deliveryPhone: userPhone,
        deliveryName: userName,
        comments: comment + ' ' + deliveryComment,
        idPostOffice: npWarehous[0]?.Ref ? npWarehous[0]?.Ref : '0',
        booking: checkWaitAll,
        deliveryPlanCode: deliveryPlan,
        deliveryAddress: addressDeliveryCity,
        itemIds: cart.selectedProducts,
        deliveryPointCode: deliveryPoints === '0' ? '' : deliveryPoints,
        deliveryDate: deliveryDate
          ? new Date(deliveryDate).toISOString()
          : null,
        paymentDate: deliveryDate ? new Date(deliveryDate).toISOString() : null,
        deliveryTypeCode: delivery,
      };
      console.log('order', data);

      dispatch(sagaOrder(data));
    } else {
      setShowError(true);
    }
  };

  const validation = () => {
    const errors: any[] = [];
    if (userName.trim().length < 5 || !userName.trim().includes(' ')) {
      errors.push('Проверьте ФИО получателя');
    }
    // if (
    //   userPhone.replace(/\D/g, '').length !== 10 ||
    //   userPhone.replace(/\D/g, '').charAt(0) !== '0'
    // ) {
    if (userPhone.replace(/\D/g, '').length < 10) {
      errors.push('Проверьте Телефон получателя');
    }
    if (deliveryType === '0') {
      errors.push('Выберите способ доставки');
    }

    if (
      (deliveryType === 'DeliveryInCity' ||
        deliveryType === 'CourierDelivery') &&
      deliveryDate === ''
    ) {
      errors.push('Укажите дату доставки');
    }

    if (deliveryType === 'DeliveryInCity' && deliveryPlan === '0') {
      errors.push('Выберите план доставки');
    }

    if (
      (deliveryType === 'DeliveryInCity' ||
        deliveryType === 'CourierDelivery') &&
      addressDeliveryCity === ''
    ) {
      errors.push('Выберите адрес доставки');
    }

    if (deliveryType === 'DeliveryByCarrier') {
      if (deliveryDate === '') {
        errors.push('Укажите дату отправки');
      }
      if (deliveryTransport === '') {
        errors.push('Укажите перевозчика');
      }
      if (!npCityRef.length && !npCityRef[0]?.Present.length) {
        errors.push('Выберите город');
      }
      if (!npWarehous.length && !npWarehous[0]?.Description.length) {
        errors.push('Выберите отделение');
      }
    }

    if (!checkGabarite) {
      errors.push('Отметьте пункт проверки на наличие габаритных деталей');
    }
    if (!checkTerms) {
      errors.push('Согласитесь с условиями поставки');
    }

    if (errors.length) {
      setErrorMessage(errors);
      return false;
    }
    return true;
  };

  const getDeliveryPlan = async (e) => {
    setDeliveryDate(e.target.value);
    setDeliveryPlan('0');
    const data = await request(`plans/?date=${e.target.value}`);
    setDeliveryPlanData(data);
  };

  return (
    <>
      <div className="order-page">
        <div className="user-area">
          <Form.Group className="mb-3">
            <Form.Label className="custom-label">
              ФИО получателя{' '}
              {profileData.deliveryName &&
                typeof profileData.deliveryName === 'string' && (
                  <button
                    className={
                      userName ? 'set-default-btn disable' : 'set-default-btn'
                    }
                    disabled={Boolean(userName)}
                    onClick={() => {
                      setUserName(profileData.deliveryName);
                    }}
                  >
                    {userName
                      ? 'Установлено с профиля'
                      : 'Установить с профиля'}
                  </button>
                )}
            </Form.Label>
            <Form.Control
              className="custom-input"
              type="text"
              placeholder="Укажите ФИО получателя"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="custom-label">
              Телефон получателя{' '}
              {profileData.deliveryPhone &&
                typeof profileData.deliveryPhone === 'string' &&
                profileData.deliveryPhone.trim().length > 9 && (
                  <button
                    className={
                      userPhone ? 'set-default-btn disable' : 'set-default-btn'
                    }
                    disabled={Boolean(userPhone)}
                    onClick={() => {
                      setUserPhone(profileData.deliveryPhone);
                    }}
                  >
                    {userPhone
                      ? 'Установлено с профиля'
                      : 'Установить с профиля'}
                  </button>
                )}
            </Form.Label>
            <InputGroup className="mb-3">
              {/* <InputGroup.Text id="user-phone" className="rounded-0">
                +38
              </InputGroup.Text> */}
              <Form.Control
                aria-describedby="user-phone"
                className="custom-input no-spin"
                // type="number"
                placeholder="Укажите телефон получателя"
                value={userPhone}
                onChange={(e) => phoneHandler(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
        <div className="delivery">
          <Form.Group className="mb-3">
            <Form.Label>Способ доставки/выдачи</Form.Label>
            <Form.Control
              as="select"
              custom
              className="rounded-0"
              value={delivery}
              onChange={(e) => deliveryHandler(e.target.value)}
            >
              {deliveryType === '0' && (
                <option value="0">Укажите способ доставки/выдачи</option>
              )}
              {deliveryTypeData.map((item: any) => (
                <option key={item.code} value={item.code}>
                  {item.name}
                </option>
              ))}
              {/* <option value="0">Укажите способ доставки/выдачи</option>
              <option value="1">Самовывоз</option>
              <option value="2">Доставка по городу</option>
              <option value="3">Доставка курьером компании</option>
              <option value="4">Отправка перевозчиком</option> */}
            </Form.Control>
          </Form.Group>
        </div>
        {deliveryType === 'DeliveryInCity' && (
          <div className="delivery-options">
            <Form.Group className="mb-3">
              <Form.Label>Адрес доставки</Form.Label>
              <Form.Control
                as="select"
                custom
                className="rounded-0"
                value={deliveryPoints}
                onChange={(e) => addressHandler(e.target.value)}
              >
                {deliveryPointsData.length ? (
                  deliveryPointsData.map((item: any) => (
                    <option key={item.code} value={item.code}>
                      {item.description}
                    </option>
                  ))
                ) : (
                  <option value="0">
                    Адрес доставки должен быть заполнен в профиле
                  </option>
                )}
              </Form.Control>
            </Form.Group>
            <div className="grid-wrapper">
              <Form.Group className="mb-3">
                <Form.Label className="custom-label">Дата доставки </Form.Label>
                <Form.Control
                  className="custom-input"
                  type="date"
                  min={checkWaitAll ? getMaxDate(1) : getMinDate(1)}
                  max={checkWaitAll ? getMaxDate(31) : getMinDate(31)}
                  value={deliveryDate}
                  onChange={(e) => getDeliveryPlan(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>План доставки</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  className="rounded-0"
                  value={deliveryPlan}
                  onChange={(e) => setDeliveryPlan(e.target.value)}
                >
                  <option value="0">Выберите план доставки</option>
                  {deliveryPlanData.map((item: any, ind) => (
                    <option key={ind} value={item.code}>
                      {item.description}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Комментарий к доставке</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Комментарий..."
                className="rounded-0"
                value={deliveryComment}
                onChange={(e) => setDeliveryComment(e.target.value)}
              />
            </Form.Group>
          </div>
        )}
        {deliveryType === 'CourierDelivery' && (
          <div className="delivery-options">
            <Form.Group className="mb-3">
              <Form.Label>Адрес доставки</Form.Label>
              <Form.Control
                as="select"
                custom
                className="rounded-0"
                value={deliveryPoints}
                onChange={(e) => addressHandler(e.target.value)}
              >
                {deliveryPointsData.length ? (
                  deliveryPointsData.map((item: any) => (
                    <option key={item.code} value={item.code}>
                      {item.description}
                    </option>
                  ))
                ) : (
                  <option value="0">
                    Адрес доставки должен быть заполнен в профиле
                  </option>
                )}
              </Form.Control>
            </Form.Group>
            <div className="grid-wrapper">
              <Form.Group className="mb-3">
                <Form.Label className="custom-label">Дата доставки </Form.Label>
                <Form.Control
                  className="custom-input"
                  type="date"
                  min={checkWaitAll ? getMaxDate(1) : getMinDate(1)}
                  max={checkWaitAll ? getMaxDate(31) : getMinDate(31)}
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                />
              </Form.Group>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Комментарий к доставке</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Комментарий..."
                className="rounded-0"
                value={deliveryComment}
                onChange={(e) => setDeliveryComment(e.target.value)}
              />
            </Form.Group>
          </div>
        )}
        {deliveryType === 'DeliveryByCarrier' && (
          <div className="delivery-options">
            <div className="grid-wrapper">
              <Form.Group className="mb-3">
                <Form.Label className="custom-label">Дата отправки </Form.Label>
                <Form.Control
                  className="custom-input"
                  type="date"
                  min={checkWaitAll ? getMaxDate(1) : getMinDate(1)}
                  max={checkWaitAll ? getMaxDate(31) : getMinDate(31)}
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label className="custom-label">
                  Город{' '}
                  {profileData.regionName &&
                    typeof profileData.regionName === 'string' && (
                      <button
                        className={
                          userName
                            ? 'set-default-btn disable'
                            : 'set-default-btn'
                        }
                        disabled={Boolean(regionName)}
                        onClick={() => {
                          setRegionName(profileData.regionName);
                        }}
                      >
                        {regionName
                          ? 'Установлено с профиля'
                          : 'Установить с профиля'}
                      </button>
                    )}
                </Form.Label>
                <Form.Control
                  className="custom-input"
                  type="text"
                  placeholder="Город"
                  value={regionName}
                  onChange={(e) => setRegionName(e.target.value)}
                />
              </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Label className="custom-label">
                  Наименование перевозчика
                </Form.Label>
                <Form.Control
                  as="select"
                  custom
                  placeholder="Наименование перевозчика"
                  className="rounded-0"
                  value={deliveryTransport}
                  onChange={(e) => setDeliveryTransport(e.target.value)}
                >
                  <option value="0">Новая Почта</option>
                </Form.Control>
              </Form.Group>
              <GetSettlements setNpCityRef={setNpCityRef} />
              <GetWarehouses
                setNpWarehous={setNpWarehous}
                npCityRef={npCityRef}
              />
              {/* <Form.Group className="mb-3">
                <Form.Label className="custom-label">
                  Номер отделения
                </Form.Label>
                <Form.Control
                  className="custom-input"
                  type="text"
                  placeholder="Номер отделения"
                  value={pointNumber}
                  onChange={(e) => setPointNumber(e.target.value)}
                />
              </Form.Group> */}
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Комментарий к доставке</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Комментарий..."
                className="rounded-0"
                value={deliveryComment}
                onChange={(e) => setDeliveryComment(e.target.value)}
              />
            </Form.Group>
          </div>
        )}
        <div className="user-comment">
          <Form.Group className="mb-3">
            <Form.Label>Коммкнтарий к заказу</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Комментарий..."
              className="rounded-0"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>
        </div>
        <div
          className={
            deliveryType === '0' || deliveryType === 'Pickup'
              ? 'agree-area'
              : 'agree-area down'
          }
        >
          <Form.Group className="mb-3" controlId="wait-all">
            <Form.Check
              type="checkbox"
              label="Доставить/выдать, когда заказ будет собран полностью"
              onChange={() => checkWaitAllHandler()}
            />
            <span className="agree-area_descr">
              Если хотите, что бы заказ был доставлен, когда он собран
              (укомплектован) полностью. Товар с наших остатков будет поставлен
              в резерв до поступления всех товаров с партнёрских складов.
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="gabarit">
            <Form.Check
              type="checkbox"
              label="
            Заказ проверен на наличие/отсутствие габаритных деталей"
              onChange={() => setCheckGabarite((prev) => !prev)}
            />
            <span className="agree-area_descr">
              Если деталь является габаритной, установите отметки Габарита в
              Корзине напротив артикулов, менеджеры уточнят затраты на доставку
              и свяжуться с Вами. В противном случае Вы соглашаетесь без
              дополнительного согласования с доплатой за доставку габаритных
              детали. Ознакомиться с списком габаритных деталей можно, перейдя
              по ссылке.
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="terms">
            <Form.Check
              type="checkbox"
              label="
            Согласен с условиями поставки"
              onChange={() => setCheckTerms((prev) => !prev)}
            />
            <span className="agree-area_descr">
              С условиями размещения заказа можете ознакомиться, перейдя по
              ссылке.
            </span>
          </Form.Group>
        </div>
      </div>
      <div className="position-relative">
        <Button
          onClick={() => sendOrder()}
          className="btn-second mt-3 d-block mx-auto position-relative px-5 py-3"
          disabled={cart.loading}
        >
          <h3>{cart.success ? 'Успешно' : 'Отправить заказ менеджеру'}</h3>
          {cart.loading && (
            <div
              className="spinner-border position-absolute"
              role="status"
              style={{
                width: '1rem',
                height: '1rem',
                top: '21px',
                right: '18px',
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </Button>
        <Toast
          onClose={() => setShowError(false)}
          show={showError}
          delay={10000}
          autohide
          className="toast-validation-error"
        >
          <Toast.Header>
            <strong className="me-auto c-main-red">Ошибка</strong>
          </Toast.Header>
          <Toast.Body>
            {errorMessage.map((item, ind) => (
              <div key={ind} className="errors-message_item">
                {item}
              </div>
            ))}
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
}
